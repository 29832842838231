import i18n from '../i18n';
import { SELECT_LANGUAGE } from '../actions/ActionTypes';
import { defaultLang } from '../config';

const initialState = {
  ...i18n,
  myLang: defaultLang
};

export default function i18nReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_LANGUAGE:
      return {
        ...state,
        myLang: action.payload.lang ? action.payload.lang.toString().toLowerCase() : 'en'
      };
    default:
      return state;
  }
}
