import * as types from './ActionTypes';
import indexOf from 'lodash/indexOf'
import Slice from 'lodash/slice';
import IsString from 'lodash/isString';
import Flatten from 'lodash/flatten';
import Range from 'lodash/range';
import truncate from '../components/truncate'
import {
  API_ROOT,
  OTHER_PLEASE_SPECIFIC,
  GLOBAL_REQUIRED,
  MAIN_DRIVER_DETAILS_PATTEN,
} from '../config'
import { I18nKey2Value } from '../utils/functions'

const action = (type, payload = {}) => ({
  type,
  payload: { ...payload }
})

/* i18n */
export const selectLanguage = lang => action(types.SELECT_LANGUAGE, { lang });

/* your details */
export const SetCarMake = ({ value }) => (dispatch, getState) => {
  const state = getState();
  let carMakeIndex = indexOf(state.default.yourdetails.carMake.menuItems, value)

  // set car make field
  dispatch(action(types.SET_CAR_MAKE_FIELD, { value }))

  // set car model / specific car model
  if (value === OTHER_PLEASE_SPECIFIC) {
    dispatch(action(types.SET_CAR_MODEL_FIELD, {
      value: OTHER_PLEASE_SPECIFIC,
      menuItems: [OTHER_PLEASE_SPECIFIC],
      disabled: true,
    }))
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value: "", helperText: GLOBAL_REQUIRED }))
  } else {
    dispatch(action(types.SET_CAR_MODEL_FIELD, {
      value: "",
      menuItems: [],
      disabled: false
    }))
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value: "", helperText: '' }))
  }

  if (carMakeIndex >= 0 && (carMakeIndex + 1) !== state.default.yourdetails.carMake.menuItems.length) {
    fetch(`${API_ROOT}/carmakes/${carMakeIndex}/carmodels`).
      then(r => r.json()).
      then(({ data: menuItems }) => {
        menuItems.push(OTHER_PLEASE_SPECIFIC)
        dispatch(action(types.LOAD_CAR_MODELS, { menuItems }))
      })
  }
}
export const SetCarModel = ({ value, isLastItem }) => (dispatch, getState) => {
  dispatch(action(types.SET_CAR_MODEL_FIELD, { value, disabled: false }))
  if (isLastItem) {
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value: "", helperText: GLOBAL_REQUIRED }))
  } else {
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value: "", helperText: '' }))
  }
}
export const SetSpecificCarModel = ({ value }) => (dispatch, getState) => {
  if (value === "") {
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value, helperText: GLOBAL_REQUIRED }))
  } else {
    dispatch(action(types.SET_SPECIFIC_CAR_MODEL_FIELD, { value, helperText: '' }))
  }
}
export const SetInsurnaceType = ({ value }) => action(types.SET_INSURANCE_TYPE_FIELD, { value })
export const SetNoClaimsDiscount = ({ value }) => action(types.SET_NO_CLAIMS_DISCOUNT_FIELD, { value })
export const SetYearOfManufacture = ({ value }) => action(types.SET_YEAR_OF_MANUFACTURE_FIELD, { value })
export const SetCubicCapacity = ({ value }) => action(types.SET_CUBICCAPACITY_FIELD, { value })
export const SetNoOfNamedDriver = ({ value }) => (dispatch, getState) => {
  const state = getState();
  const currentCount = (value || 0)
  const previousCount = state.default.yourdetails.noOfNamedDriver.value
  const diff = currentCount - previousCount
  let mainDriverDetails = state.default.yourdetails.mainDriverDetails
  if (diff > 0) {
    // insert
    mainDriverDetails.splice(previousCount, diff, ...[...Array(diff).keys()].map(x => (MAIN_DRIVER_DETAILS_PATTEN)))
  } else {
    // remove
    mainDriverDetails.splice(currentCount, Math.abs(diff))
  }
  dispatch(action(types.SET_NO_OF_NAMED_DRIVER_FIELD, { value }))
  dispatch(action(types.SET_MAIN_DRIVER_DETAILS_FIELD, { mainDriverDetails }))
}
export const SetAge = ({ index, value }) => action(types.SET_AGE_FIELD, { index, value })
export const SetOccupationIndustry = ({ index, value, isLastItem }) => (dispatch, getState) => {
  if (isLastItem) {
    dispatch(action(types.SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD, { index, value: '', helperText: GLOBAL_REQUIRED }))
  } else {
    dispatch(action(types.SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD, { index, value: '', helperText: '' }))
  }

  dispatch(action(types.SET_OCCUPATION_INDUSTRY_FIELD, { index, value }))
}
export const SetSpecificOccupationIndustry = ({ index, value }) => (dispatch, getState) => {
  if (value === "") {
    dispatch(action(types.SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD, { index, value, helperText: GLOBAL_REQUIRED }))
  } else {
    dispatch(action(types.SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD, { index, value, helperText: '' }))
  }
}
export const SetYearsOfDrivingExperience = ({ index, value }) => action(types.SET_YEARS_OF_DRIVING_EXPERIENCE_FIELD, { index, value })
export const SetQa1 = ({ index, value }) => (dispatch, getState) => {
  dispatch(action(types.SET_QA1_FIELD, { index, value }))
  if (value === 'global.no') {
    dispatch(SetEq1YesComment({ index, value: '', helperText: GLOBAL_REQUIRED }))
  }
}
export const SetEq1YesComment = ({ index, value }) => action(types.SET_EQ1_YES_COMMENT, { index, value })
export const SetQa2 = ({ index, value }) => (dispatch, getState) => {
  dispatch(action(types.SET_QA2_FIELD, { index, value }))
  if (value === 'global.no') {
    dispatch(SetEq2YesComment({ index, value: '', helperText: GLOBAL_REQUIRED }))
  }
}
export const SetEq2YesComment = ({ index, value }) => action(types.SET_EQ2_YES_COMMENT, { index, value })

/* concat */
export const SetName = ({ value }) => action(types.SET_NAME_FIELD, { value })
export const SetEmail = ({ value }) => action(types.SET_EMAIL_FIELD, { value })
export const SetMobileNo = ({ value }) => action(types.SET_MOBILE_NO_FIELD, { value })
export const SetWhatsApp = ({ value }) => action(types.SET_WHATSAPP_FIELD, { value })
export const SetWeChat = ({ value }) => action(types.SET_WECHAT_FIELD, { value })

/* more details */
export const SetApproximateStartDate = ({ value }) => action(types.SET_APPROXIMATE_START_DATE_FIELD, { value })
export const SetIsLocation = ({ value }) => action(types.SET_ISLOCATION_FIELD, { value })
export const SetRemark = ({ value }) => action(types.SET_REMARK_FIELD, { value })
export const SetreferralCode = ({ value }) => action(types.SET_REFERRAL_CODE, { value })

/* snackbars */
export const OpenSnackbars = ({ message }) => action(types.OPEN_OPENSNACKBARS, { message })
export const CloseSnackbars = () => action(types.CLOSE_OPENSNACKBARS)

/* steps */
export const goNext = () => (dispatch, getState) => {
  const state = getState();
  const {
    yourdetails: yd,
    contact: c,
    moredetails: md,
    i18n,
  } = state.default;

  // error check
  // check required fields
  let message = []
  switch (state.default.steps.activeStep) {
    case 0:
      // your detail
      const y = Object.keys(state.default.yourdetails).map((key, index) => {
        const item = state.default.yourdetails[key]
        if (key === 'mainDriverDetails') {
          return Slice(yd.mainDriverDetails, 0, yd.noOfNamedDriver.value).map((mdd, index) => {
            return [
              mdd.age,
              mdd.occupationIndustry,
              mdd.specificOccupationIndustry,
              mdd.yearsOfDrivingExperience,
              mdd.qa1,
              mdd.qa1.value === 'global.yes' ? mdd.eq1YesComment : null,
              mdd.qa2,
              mdd.qa2.value === 'global.yes' ? mdd.eq2YesComment : null,
            ].filter((x) => x)
          })
        }
        return item
      })

      const x = Flatten(Flatten(y)).
        filter(({ helperText }) => helperText)
        .forEach(item => message.push(`${truncate(i18n[i18n.myLang][item.label], 30)} ${I18nKey2Value(item.helperText, i18n)}`))
      break;

    case 1:
      // contact info
      Object.keys(state.default.contact).
        map((key) => state.default.contact[key]).
        filter(({ helperText }) => helperText).
        forEach(item => message.push(`${truncate(i18n[i18n.myLang][item.label], 30)} ${I18nKey2Value(item.helperText, i18n)}`))
      break;

    case 2:
      // more details
      Object.keys(state.default.moredetails).
        map((key) => state.default.moredetails[key]).
        filter((moredetails) => moredetails.isRequired && moredetails.value === '').
        forEach(item => message.push(`${truncate(i18n[i18n.myLang][item.label], 30)} ${i18n[i18n.myLang][GLOBAL_REQUIRED]}`))
      break;

    default:
      break;
  }

  if (message.length) {
    dispatch(action(types.OPEN_OPENSNACKBARS, { message: message.join(', ') }))
  } else {
    dispatch(action(types.GO_NEXT))
  }

  if (getState().default.steps.activeStep === getState().default.steps.steps.length) {

    const headers_m = Slice(yd.mainDriverDetails, 0, yd.noOfNamedDriver.value).map((mdd, index) => {
      return [
        `Driver-${index}: ${mdd.age.label}`,
        `Driver-${index}: ${mdd.occupationIndustry.label}`,
        `Driver-${index}: ${mdd.specificOccupationIndustry.label}`,
        `Driver-${index}: ${mdd.yearsOfDrivingExperience.label}`,
        `Driver-${index}: ${mdd.qa1.label}`,
        `Driver-${index}: ${mdd.eq1YesComment.label}`,
        `Driver-${index}: ${mdd.qa2.label}`,
        `Driver-${index}: ${mdd.eq2YesComment.label}`,
      ]
    })
    const headers = [].concat.apply([], [
      yd.insurranceDetails.label,
      yd.noClaimsDiscount.label,
      yd.carMake.label,
      yd.carModel.label,
      yd.specificCarModel.label,
      yd.yearOfManufacture.label,
      yd.cubicCapacity.label,
      yd.noOfNamedDriver.label,
      ...headers_m,
      c.name.label,
      c.email.label,
      c.mobileNo.label,
      c.whatsapp.label,
      c.wechat.label,
      md.approximateStartDate.label,
      md.isLocation.label,
      md.remark.label,
      md.referralCode.label,
    ])

    const data_m = Slice(yd.mainDriverDetails, 0, yd.noOfNamedDriver.value).map((mdd, index) => {
      return [
        mdd.age.value,
        mdd.occupationIndustry.value,
        mdd.specificOccupationIndustry.value,
        mdd.yearsOfDrivingExperience.value,
        mdd.qa1.value,
        mdd.eq1YesComment.value,
        mdd.qa2.value,
        mdd.eq2YesComment.value,
      ]
    })
    const data = [].concat.apply([], [
      yd.insurranceDetails.value,
      yd.noClaimsDiscount.value,
      yd.carMake.value,
      yd.carModel.value,
      yd.specificCarModel.value,
      yd.yearOfManufacture.value,
      yd.cubicCapacity.value,
      yd.noOfNamedDriver.value,
      ...data_m,
      c.name.value,
      c.email.value,
      c.mobileNo.value,
      c.whatsapp.value,
      c.wechat.value,
      md.approximateStartDate.value,
      md.isLocation.value,
      md.remark.value,
      md.referralCode.value,
    ])

    // print header
    // DONOT REMOVE
    // console.log(headers.map(label => {
    //   const x = `${I18nKey2Value(label, i18n)}`
    //   const y = `${truncate(x, 40)}`
    //   return y
    // }))

    if (yd.noOfNamedDriver.value == 1) {
      // need to append 
      data.splice(15, 0, ...Range(data_m[0].length).map(x => ""))
    }

    const postData = data.map(value => !IsString(value) ? value.toString() : (value ? I18nKey2Value(value, i18n) : value))
    dispatch(OrderSubmit({ data: postData }))
  }
}
export const goBack = () => action(types.GO_BACK)

/* orders */
export const OrderSubmit = ({ data }) => (dispatch, getState) => {
  dispatch(action(types.ORDER_SUBMITTING))
  fetch(`${API_ROOT}/survey`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data })
  }).
    then((response) => {
      if (response.status != 200) {
        throw Error(response.statusText);
      }
      return response
    }).
    then(r => r.json()).
    then(({ data }) => {
      dispatch(action(types.ORDER_SUBMITTED, { order: data, error: '' }))
    }).catch(({ message }) => {
      dispatch(action(types.ORDER_SUBMITTED, { order: data, error: message }))
      dispatch(action(types.OPEN_OPENSNACKBARS, { message }))
    })
}

export const OrderReset = () => (dispatch, getState) => {
  dispatch(action(types.GO_ZERO))
  dispatch(action(types.ORDER_RESET))

  dispatch(action(types.RESET_YOURDETAILS))
  dispatch(action(types.RESET_MORE_DETAILS))
  dispatch(action(types.RESET_CONTACT))
  dispatch(action(types.RESET_PRIVACY_POLICY))

  fetch(`${API_ROOT}/carmakes`).
    then(r => r.json()).
    then(({ data: menuItems }) => {
      menuItems.push(OTHER_PLEASE_SPECIFIC)
      dispatch(action(types.LOAD_CAR_MAKES, { menuItems }))
    })
}

/* privacy policy */
export const SetPrivacyPolicyAgree = ({ value }) => action(types.SET_PRIVACY_POLICY_AGREE_FIELD, { value });