import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import kebabCase from 'lodash/kebabCase';
import { I18nKey2Value } from '../utils/functions'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class MySelect extends React.Component {
  constructor(props) {
    super(props)
  }

  handleChange = (event, index) => {
    if (this.props.onChange) {
      const { items } = this.props;
      this.props.onChange(event.target.value, index === items.length - 1)
    }
  };

  render() {
    const { classes } = this.props;
    const { label, isRequired, items = [], i18n, helperText } = this.props
    return (
      <form className={classes.root} autoComplete="off">
        <FormControl fullWidth className={classes.formControl} error={this.props.error} disabled={this.props.disabled}>
          <InputLabel htmlFor={isRequired ? `${kebabCase(label)}-required` : `${kebabCase(label)}`}>{label}</InputLabel>
          <Select
            value={this.props.value || ''}
            onChange={(e) => this.handleChange(e, parseInt(e.currentTarget.dataset.index, 10))}
            name={label}
            inputProps={{
              id: isRequired ? `${kebabCase(label)}-required` : `${kebabCase(label)}`,
            }}
            className={classes.selectEmpty}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {items.map((item, index) => (<MenuItem key={item} data-index={index} value={item}>{i18n[i18n.myLang][item] ? i18n[i18n.myLang][item] : item}</MenuItem>))}
          </Select>

          <FormHelperText>{I18nKey2Value(helperText, i18n)}</FormHelperText>
        </FormControl>
      </form>
    );
  }
}

MySelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MySelect);