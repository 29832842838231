import React from 'react';
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Insurnace from '../insurance'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../img/logo.png'
import { localeMapping, mapi18nkey2Lang } from '../../i18n'
import * as actions from '../../actions';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  appBar: {
    position: 'relative',
  },
  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  avatar: {
    marginRight: 10,
  },
});

class App extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (e, lang) => {
    if (lang !== 'backdropClick') {
      const { selectLanguage } = this.props;
      selectLanguage(lang);
      if (global.localStorage) {
        localStorage.setItem('myLang', lang);
      }
    }

    this.setState({ anchorEl: null });
  };

  getLangList = () => {
    const { i18n } = this.props;
    const langs = Object.keys(localeMapping)

    let langList = langs.map((lang, idx) => {
      if (lang === 'en') {
        return {
          id: idx + 1,
          text: mapi18nkey2Lang(lang),
          checked: i18n.myLang && i18n.myLang.includes('en'),
          name: 'en'
        }
      }

      return {
        id: idx + 1,
        text: mapi18nkey2Lang(lang),
        checked: i18n.myLang && i18n.myLang === langs[idx],
        name: langs[idx]
      }
    })

    return langList;
  }

  render() {
    const {
      classes,
      i18n,
      contact,
      moredetails,
      snackbars,
      steps,
      yourdetails,
      orders,
      privacypolicy,
      // actions
      goNext,
      goBack,
      OpenSnackbars,
      CloseSnackbars,
      // actions: your details
      SetCarMake,
      SetCarModel,
      SetSpecificCarModel,
      SetInsurnaceType,
      SetNoClaimsDiscount,
      SetYearOfManufacture,
      SetCubicCapacity,
      SetNoOfNamedDriver,
      SetAge,
      SetOccupationIndustry,
      SetSpecificOccupationIndustry,
      SetYearsOfDrivingExperience,
      SetQa1,
      SetEq1YesComment,
      SetQa2,
      SetEq2YesComment,
      // actions: contact
      SetName,
      SetEmail,
      SetMobileNo,
      SetWhatsApp,
      SetWeChat,
      // actions: more detail
      SetApproximateStartDate,
      SetIsLocation,
      SetRemark,
      SetreferralCode,
      // order
      OrderReset,
      OrderSubmit,
      // privacy policy
      SetPrivacyPolicyAgree,
    } = this.props;
    const { anchorEl } = this.state;
    const langData = this.getLangList()
    return (
      <div>
        <AppBar color="default" position="static">
          <Toolbar>
            <Avatar alt="Remy Sharp" src={logo} className={classes.avatar} />
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              Easeinsurance
          </Typography>
            <Button
              color="primary" variant="outlined"
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) => this.handleClick(e)}
            >
              {mapi18nkey2Lang(i18n.myLang)}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {langData.map(lang => <MenuItem key={lang.name} onClick={(e) => this.handleClose(e, lang.name)}>{lang.text}</MenuItem>)}
            </Menu>
          </Toolbar>
        </AppBar>
        <Insurnace
          i18n={i18n}
          contact={contact}
          moredetails={moredetails}
          snackbars={snackbars}
          steps={steps}
          yourdetails={yourdetails}
          orders={orders}
          privacypolicy={privacypolicy}
          actions={{
            goNext,
            goBack,
            OpenSnackbars,
            CloseSnackbars,
            // your details
            SetCarMake,
            SetCarModel,
            SetSpecificCarModel,
            SetInsurnaceType,
            SetNoClaimsDiscount,
            SetYearOfManufacture,
            SetCubicCapacity,
            SetNoOfNamedDriver,
            SetAge,
            SetOccupationIndustry,
            SetSpecificOccupationIndustry,
            SetYearsOfDrivingExperience,
            SetQa1,
            SetEq1YesComment,
            SetQa2,
            SetEq2YesComment,
            // contact
            SetName,
            SetEmail,
            SetMobileNo,
            SetWhatsApp,
            SetWeChat,
            // moredetails
            SetApproximateStartDate,
            SetIsLocation,
            SetRemark,
            SetreferralCode,
            // order
            OrderReset,
            OrderSubmit,
            // privacy policy
            SetPrivacyPolicyAgree,
          }}
        />
      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  const {
    i18n,
    contact,
    moredetails,
    snackbars,
    steps,
    yourdetails,
    orders,
    privacypolicy,
  } = state.default
  return {
    i18n,
    contact,
    moredetails,
    snackbars,
    steps,
    yourdetails,
    orders,
    privacypolicy,
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  selectLanguage: actions.selectLanguage,
  goNext: actions.goNext,
  goBack: actions.goBack,
  OpenSnackbars: actions.OpenSnackbars,
  CloseSnackbars: actions.CloseSnackbars,
  //your details
  SetCarMake: actions.SetCarMake,
  SetCarModel: actions.SetCarModel,
  SetSpecificCarModel: actions.SetSpecificCarModel,
  SetInsurnaceType: actions.SetInsurnaceType,
  SetNoClaimsDiscount: actions.SetNoClaimsDiscount,
  SetYearOfManufacture: actions.SetYearOfManufacture,
  SetCubicCapacity: actions.SetCubicCapacity,
  SetNoOfNamedDriver: actions.SetNoOfNamedDriver,
  SetAge: actions.SetAge,
  SetOccupationIndustry: actions.SetOccupationIndustry,
  SetSpecificOccupationIndustry: actions.SetSpecificOccupationIndustry,
  SetYearsOfDrivingExperience: actions.SetYearsOfDrivingExperience,
  SetQa1: actions.SetQa1,
  SetEq1YesComment: actions.SetEq1YesComment,
  SetQa2: actions.SetQa2,
  SetEq2YesComment: actions.SetEq2YesComment,
  // contact
  SetName: actions.SetName,
  SetEmail: actions.SetEmail,
  SetMobileNo: actions.SetMobileNo,
  SetWhatsApp: actions.SetWhatsApp,
  SetWeChat: actions.SetWeChat,
  // more detail
  SetApproximateStartDate: actions.SetApproximateStartDate,
  SetIsLocation: actions.SetIsLocation,
  SetRemark: actions.SetRemark,
  SetreferralCode: actions.SetreferralCode,
  // order
  OrderReset: actions.OrderReset,
  OrderSubmit: actions.OrderSubmit,
  // privacy policy
  SetPrivacyPolicyAgree: actions.SetPrivacyPolicyAgree
}
)(withStyles(styles)(App))