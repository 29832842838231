import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { I18nKey2Value } from '../../utils/functions'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    grid: {
        textAlign: "center"
    }
});

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            classes,
            actions,
            privacypolicy: {
                privacyPolicyAgree
            },
            i18n,
        } = this.props;

        return (
            <React.Fragment>
                <Typography variant="title" gutterBottom>
                    {i18n[i18n.myLang]["privacypolicy.typography.privacypolicy.title"]}
                </Typography>
                <List disablePadding>
                    {i18n[i18n.myLang]["privacypolicy.typography.privacypolicy.content"].split('\n').map((content, index) => (
                        <p key={index}>{content}</p>
                    ))}
                </List>
                <Grid item xs={12} sm={12} className={classes.grid}>
                    <FormControl required component="fieldset" className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={privacyPolicyAgree}
                                    onChange={(e) => actions.SetPrivacyPolicyAgree({ value: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label={i18n[i18n.myLang]["privacypolicy.typography.privacypolicy.agree"]}
                        />
                    </FormControl>
                </Grid>
            </React.Fragment>
        );
    }
}

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicy)

