import * as ActionTypes from '../actions/ActionTypes';
import validator from "validator";
import { GLOBAL_REQUIRED } from '../config'

const mobileNoRegex = /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[35678]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|66\d{2})\d{6}$|^(?:\+?852)[569][0-9]{7}$/

const initialState = {
    name: {
        label: 'contact.typography.insurancedetails.name.label',
        value: '',
        placeholder: 'contact.typography.insurancedetails.name.placeholder',
        helperText: GLOBAL_REQUIRED,
    },
    email: {
        label: 'contact.typography.insurancedetails.email.label',
        value: '',
        placeholder: 'contact.typography.insurancedetails.email.placeholder',
        helperText: GLOBAL_REQUIRED,
    },
    mobileNo: {
        label: 'contact.typography.insurancedetails.mobileno.label',
        value: '',
        placeholder: 'contact.typography.insurancedetails.mobileno.placeholder',
        helperText: GLOBAL_REQUIRED,
    },
    whatsapp: {
        label: 'contact.typography.insurancedetails.whatsapp.label',
        value: '',
        placeholder: 'contact.typography.insurancedetails.whatsapp.placeholder',
        helperText: '',
    },
    wechat: {
        label: 'contact.typography.insurancedetails.wechat.label',
        value: '',
        placeholder: 'contact.typography.insurancedetails.wechat.placeholder',
        helperText: '',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.RESET_CONTACT:
            return {
                ...initialState
            }

        case ActionTypes.SET_NAME_FIELD:
            const name = {
                ...state.name,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                name
            }

        case ActionTypes.SET_EMAIL_FIELD:
            const email = {
                ...state.email,
                value: action.payload.value,
                helperText: ((value) => {
                    let helperText = []
                    if (!value) {
                        helperText.push(GLOBAL_REQUIRED)
                    } else {
                        if (!validator.isEmail(value)) {
                            helperText.push(`contact.typography.insurancedetails.email.formaterror`)
                        }
                    }
                    return helperText.join(' ')
                })(action.payload.value)
            }
            return {
                ...state,
                email
            }

        case ActionTypes.SET_MOBILE_NO_FIELD:
            const mobileNo = {
                ...state.mobileNo,
                value: action.payload.value,
                helperText: ((value) => {
                    let helperText = []
                    if (!value) {
                        helperText.push(GLOBAL_REQUIRED)
                    } else {
                        if (!mobileNoRegex.test(value)) {
                            helperText.push(`contact.typography.insurancedetails.mobileno.formaterror`)
                        }
                    }
                    return helperText.join(' ')
                })(action.payload.value)
            }
            return {
                ...state,
                mobileNo
            }

        case ActionTypes.SET_WHATSAPP_FIELD:
            const whatsapp = { ...state.whatsapp, value: action.payload.value }
            return {
                ...state,
                whatsapp
            }

        case ActionTypes.SET_WECHAT_FIELD:
            const wechat = { ...state.wechat, value: action.payload.value }
            return {
                ...state,
                wechat
            }

        default:
            return state
    }
}
