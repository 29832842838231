export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

/* your details */
export const LOAD_CAR_MAKES = 'yourdetails/LOAD_CAR_MAKES'
export const LOAD_CAR_MODELS = 'yourdetails/LOAD_CAR_MODELS'
export const SET_CAR_MAKE_FIELD = 'yourdetails/SET_CAR_MAKE_FIELD'
export const SET_CAR_MODEL_FIELD = 'yourdetails/SET_CAR_MODEL_FIELD'
export const SET_SPECIFIC_CAR_MODEL_FIELD = 'yourdetails/SET_SPECIFIC_CAR_MODEL_FIELD'
export const SET_INSURANCE_TYPE_FIELD = 'yourdetails/SET_INSURANCE_TYPE_FIELD'
export const SET_NO_CLAIMS_DISCOUNT_FIELD = 'yourdetails/SET_NO_CLAIMS_DISCOUNT_FIELD'
export const SET_YEAR_OF_MANUFACTURE_FIELD = 'yourdetails/SET_YEAR_OF_MANUFACTURE_FIELD'
export const SET_CUBICCAPACITY_FIELD = 'yourdetails/SET_CUBICCAPACITY_FIELD'
export const SET_NO_OF_NAMED_DRIVER_FIELD = 'yourdetails/SET_NO_OF_NAMED_DRIVER_FIELD'

export const SET_MAIN_DRIVER_DETAILS_FIELD = 'yourdetails/SET_MAIN_DRIVER_DETAILS_FIELD'
export const SET_AGE_FIELD = 'yourdetails/SET_AGE_FIELD'
export const SET_OCCUPATION_INDUSTRY_FIELD = 'yourdetails/SET_OCCUPATION_INDUSTRY_FIELD'
export const SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD = 'yourdetails/SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD'
export const SET_YEARS_OF_DRIVING_EXPERIENCE_FIELD = 'yourdetails/SET_YEARS_OF_DRIVING_EXPERIENCE_FIELD'
export const SET_QA1_FIELD = 'yourdetails/SET_QA1_FIELD'
export const SET_EQ1_YES_COMMENT = 'yourdetails/SET_EQ1_YES_COMMENT'
export const SET_QA2_FIELD = 'yourdetails/SET_QA2_FIELD'
export const SET_EQ2_YES_COMMENT = 'yourdetails/SET_EQ2_YES_COMMENT'
export const RESET_YOURDETAILS = 'yourdetails/RESET_YOURDETAILS'

/* concat */
export const SET_NAME_FIELD = 'contact/SET_NAME_FIELD'
export const SET_EMAIL_FIELD = 'contact/SET_EMAIL_FIELD'
export const SET_MOBILE_NO_FIELD = 'contact/SET_MOBILE_NO_FIELD'
export const SET_WHATSAPP_FIELD = 'contact/SET_WHATSAPP_FIELD'
export const SET_WECHAT_FIELD = 'contact/SET_WECHAT_FIELD'
export const RESET_CONTACT = 'contact/RESET_CONTACT'

/* more details */
export const SET_APPROXIMATE_START_DATE_FIELD = 'moredetails/SET_APPROXIMATE_START_DATE_FIELD'
export const SET_ISLOCATION_FIELD = 'moredetails/SET_ISLOCATION_FIELD'
export const SET_REMARK_FIELD = 'moredetails/SET_REMARK_FIELD'
export const RESET_MORE_DETAILS = 'moredetails/RESET_MORE_DETAILS'
export const SET_REFERRAL_CODE = 'moredetails/SET_REFERRAL_CODE'

/* snackbars */
export const OPEN_OPENSNACKBARS = 'openSnackbars/OPEN_OPENSNACKBARS'
export const CLOSE_OPENSNACKBARS = 'openSnackbars/CLOSE_OPENSNACKBARS'

/* steps */
export const GO_NEXT = 'insurnace/GO_NEXT'
export const GO_BACK = 'insurnace/GO_BACK'
export const GO_ZERO = 'insurnace/GO_ZERO'

/* post data */
export const ORDER_SUBMITTING = 'orders/ORDER_SUBMITTING'
export const ORDER_SUBMITTED = 'orders/ORDER_SUBMITTED'
export const ORDER_RESET = 'orders/ORDER_RESET'

/* privacy policy */
export const SET_PRIVACY_POLICY_AGREE_FIELD = 'privacyPolicy/SET_PRIVACY_POLICY_AGREE_FIELD'
export const RESET_PRIVACY_POLICY = 'privacyPolicy/RESET_PRIVACY_POLICY'