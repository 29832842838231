import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    approximateStartDate: {
        isRequired: true,
        label: 'moredetails.typography.insurancedetails.approximatestartdate.label',
        value: '',
        placeholder: 'moredetails.typography.insurancedetails.approximatestartdate.placeholder'
    },
    isLocation: {
        isRequired: true,
        label: 'moredetails.typography.insurancedetails.islocation.label',
        value: 'global.yes',
        placeholder: 'moredetails.typography.insurancedetails.islocation.placeholder',
        menuItems: ["global.yes", "global.no"]
    },
    remark: {
        isRequired: false,
        label: 'moredetails.typography.insurancedetails.remark.label',
        value: '',
        placeholder: 'moredetails.typography.insurancedetails.remark.placeholder',
        helperText: 'moredetails.typography.insurancedetails.remark.helperText',
        rows: 10
    },
    referralCode: {
        isRequired: false,
        label: 'moredetails.typography.insurancedetails.referralcode.label',
        value: '',
        placeholder: 'moredetails.typography.insurancedetails.referralcode.placeholder',
        helperText: 'moredetails.typography.insurancedetails.referralcode.helperText',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.RESET_MORE_DETAILS:
            return {
                ...initialState
            }
        case ActionTypes.SET_APPROXIMATE_START_DATE_FIELD:
            const approximateStartDate = {
                ...state.approximateStartDate,
                value: action.payload.value,
            }
            return {
                ...state,
                approximateStartDate
            }

        case ActionTypes.SET_ISLOCATION_FIELD:
            const isLocation = {
                ...state.isLocation,
                value: action.payload.value,
            }
            return {
                ...state,
                isLocation,
            }

        case ActionTypes.SET_REMARK_FIELD:
            const remark = {
                ...state.remark,
                value: action.payload.value,
            }
            return {
                ...state,
                remark
            }

        case ActionTypes.SET_REFERRAL_CODE:
            const referralCode = {
                ...state.referralCode,
                value: action.payload.value,
            }
            return {
                ...state,
                referralCode,
            }

        default:
            return state
    }
}