import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import YourDetails from './YourDetails';
import Contact from './Contact';
import MoreDetails from './MoreDetails';
import MySnackbarContentWrapper from '../../components/MySnackbarContentWrapper'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import Snackbar from '@material-ui/core/Snackbar';
import Review from './Review';
import PrivacyPolicy from './Privacypolicy';
import reactStringReplace from 'react-string-replace'

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(800 + theme.spacing.unit * 2 * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(800 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  stepperLabel: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class Insurance extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      classes,
      actions,
      steps: { activeStep, steps },
      snackbars,
      i18n,
      yourdetails,
      contact,
      moredetails,
      orders,
      privacypolicy,
    } = this.props;

    let stepContent
    switch (activeStep) {
      case 0:
        stepContent = <YourDetails
          i18n={i18n}
          yourdetails={yourdetails}
          actions={actions}
        />
        break
      case 1:
        stepContent = <Contact
          i18n={i18n}
          contact={contact}
          actions={actions}
        />
        break
      case 2:
        stepContent = <MoreDetails
          i18n={i18n}
          yourdetails={yourdetails}
          moredetails={moredetails}
          actions={actions}
        />
        break
      case 3:
        stepContent = <Review
          i18n={i18n}
          yourdetails={yourdetails}
          contact={contact}
          moredetails={moredetails}
          actions={actions}
        />
        break
      case 4:
        stepContent = <PrivacyPolicy
          i18n={i18n}
          actions={actions}
          privacypolicy={privacypolicy}
        />
        break
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="display1" align="center">
              Insurance
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel classes={{
                    label: classes.stepperLabel
                  }}>{i18n[i18n.myLang][label]}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {orders.submitting ? (<Typography align="center" variant="headline" gutterBottom>
                    <CircularIndeterminate></CircularIndeterminate>
                  </Typography>) : (<div>
                    {!orders.error ? (<div>
                      <Typography align="center" variant="headline" gutterBottom>
                        {i18n[i18n.myLang]['order.submitted.title']}
                      </Typography><Typography variant="subheading">
                        {reactStringReplace(i18n[i18n.myLang]['order.submitted.description'], '{0}', (match, i) => (<Typography inline="true" color="secondary">{orders.order[1]}</Typography>))}
                      </Typography>
                      <Typography align="right">
                        <Button onClick={actions.OrderReset} className={classes.button}>
                          {i18n[i18n.myLang]['global.reset']}
                        </Button>
                      </Typography>
                    </div>) : (<Typography align="center">
                      <Button variant="contained" disabled={!privacypolicy.privacyPolicyAgree} color="primary" onClick={() => actions.OrderSubmit({ data: orders.order })} className={classes.button}>
                        {i18n[i18n.myLang]['global.placeorder']}
                      </Button>
                      <Button onClick={actions.OrderReset} className={classes.button}>
                        {i18n[i18n.myLang]['global.cancel']}
                      </Button>
                    </Typography>)}
                  </div>)}
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    {stepContent}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={actions.goBack} className={classes.button}>
                          {i18n[i18n.myLang]['global.goback']}
                        </Button>
                      )}
                      <Button
                        disabled={!privacypolicy.privacyPolicyAgree && activeStep === 4}
                        variant="contained"
                        color="primary"
                        onClick={actions.goNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? i18n[i18n.myLang]['global.placeorder'] : i18n[i18n.myLang]['global.gonext']}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
            </React.Fragment>
          </Paper>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbars.open}
            autoHideDuration={snackbars.autoHideDuration}
            onClose={actions.CloseSnackbars}
          >
            <MySnackbarContentWrapper
              onClose={actions.CloseSnackbars}
              variant={snackbars.variant}
              message={snackbars.message}
            />
          </Snackbar>
        </main>
      </React.Fragment>
    );
  }
}

Insurance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Insurance)

