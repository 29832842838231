import { combineReducers } from 'redux'
import yourdetails from './yourdetails'
import steps from './steps'
import snackbars from './snackbars'
import contact from './contact'
import moredetails from './moredetails'
import orders from './orders'
import i18n from './i18n';
import privacypolicy from './privacypolicy'

export default combineReducers({
  snackbars,
  steps,
  yourdetails,
  contact,
  moredetails,
  i18n,
  orders,
  privacypolicy,
})