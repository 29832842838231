import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    submitting: true,
    error: '',
    order: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ORDER_SUBMITTING:
            return {
                ...state,
                submitting: true,
            }

        case ActionTypes.ORDER_SUBMITTED:
            return {
                ...state,
                submitting: false,
                order: action.payload.order,
                error: action.payload.error,
            }

        case ActionTypes.ORDER_RESET:
            return {
                ...state,
                submitting: false,
                order: [],
            }

        default:
            return state
    }
}