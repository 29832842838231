import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { I18nKey2Value } from '../../utils/functions'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    marginTop: 0
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class MoreDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      classes,
      actions,
      yourdetails: {
        insurranceDetails,
        noClaimsDiscount
      },
      moredetails: {
        approximateStartDate,
        isLocation,
        remark,
        referralCode,
      },
      i18n
    } = this.props;

    return (
      <React.Fragment>
        <Typography variant="title" gutterBottom>
          {i18n[i18n.myLang]["contact.typography.insurancedetails.title"]}
        </Typography>
        <List disablePadding>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][insurranceDetails.label]}>
            <ListItemText primary={i18n[i18n.myLang][insurranceDetails.label]} />
            <Typography variant="body2">{insurranceDetails.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][noClaimsDiscount.label]}>
            <ListItemText primary={i18n[i18n.myLang][noClaimsDiscount.label]} />
            <Typography variant="body2">{noClaimsDiscount.value}</Typography>
          </ListItem>
        </List>
        <Grid item xs={12} sm={12}>
          <form className={classes.root} autoComplete="off">
            <FormControl fullWidth className={classes.formControl} error={approximateStartDate.isRequired && approximateStartDate.value === '' ? true : false}>
              <InputLabel htmlFor="component-helper" shrink={true}>{approximateStartDate.isRequired ? `${i18n[i18n.myLang]['global.required']}*` : ''}</InputLabel>
              <Input
                type="date"
                value={approximateStartDate.value}
                onChange={(e) => actions.SetApproximateStartDate({ value: e.target.value })}
              />
              <FormHelperText>{i18n[i18n.myLang][approximateStartDate.label]}</FormHelperText>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl component="fieldset" className={classes.formControl} error={isLocation.isRequired && isLocation.value === '' ? true : false}>
            <FormLabel component="legend" style={{ lineHeight: '1.5em' }}>{i18n[i18n.myLang][isLocation.label]}</FormLabel>
            <RadioGroup
              row
              aria-label="Gender"
              className={classes.group}
              value={i18n[i18n.myLang][isLocation.value]}
              onChange={(e) => actions.SetIsLocation({ value: e.target.parentElement.parentElement.parentElement.dataset.key })}
            >
              {isLocation.menuItems.map((item, index) => (
                <FormControlLabel key={`item-${index}`} data-key={item} value={i18n[i18n.myLang][item]} control={<Radio />} label={i18n[i18n.myLang][item]} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            error={remark.isRequired && remark.value === '' ? true : false}
            label={i18n[i18n.myLang][remark.label]}
            style={{ margin: 8 }}
            placeholder={i18n[i18n.myLang][remark.placeholder]}
            helperText={i18n[i18n.myLang][remark.helperText]}
            value={remark.value}
            fullWidth
            margin="normal"
            rows={remark.rows}
            multiline
            onChange={(e) => actions.SetRemark({ value: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={referralCode.isRequired && referralCode.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][referralCode.label]}</InputLabel>
                <Input
                  value={referralCode.value}
                  placeholder={i18n[i18n.myLang][referralCode.placeholder]}
                  onChange={(e) => actions.SetreferralCode({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(referralCode.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>        
      </React.Fragment>
    )
  }
}

MoreDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MoreDetails)