const regex = /\w+(\.|\w)+/gm;
export function I18nKey2Value(str, i18n) {
    let m;
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        if (i18n[i18n.myLang][m[0]]) {
            str = str.replace(new RegExp(m[0], "gm"), i18n[i18n.myLang][m[0]])
        }
    }
    return str
}