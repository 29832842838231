import * as ActionTypes from '../actions/ActionTypes';
import { STEPS } from '../config'

const initialState = {
    activeStep: 0,
    steps: STEPS,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GO_NEXT:
            return {
                ...state,
                activeStep: state.activeStep + 1
            }

        case ActionTypes.GO_BACK:
            return {
                ...state,
                activeStep: state.activeStep - 1
            }

        case ActionTypes.GO_ZERO:
            return {
                ...state,
                activeStep: 0
            }

        default:
            return state
    }
}