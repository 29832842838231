import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextMaskCustom from '../../components/TextMaskCustom'
import emailMask from 'text-mask-addons/dist/emailMask'
import { I18nKey2Value } from '../../utils/functions'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    marginTop: 0
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class ContactInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes,
      actions,
      i18n,
      contact: {
        name,
        email,
        mobileNo,
        whatsapp,
        wechat,
      }
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={name.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][name.label]}</InputLabel>
                <Input
                  value={name.value}
                  placeholder={i18n[i18n.myLang][name.placeholder]}
                  onChange={(e) => actions.SetName({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(name.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={12}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={email.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][email.label]}</InputLabel>
                <Input
                  value={email.value}
                  placeholder={i18n[i18n.myLang][email.placeholder]}
                  onChange={(e) => actions.SetEmail({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(email.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={12}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={mobileNo.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][mobileNo.label]}</InputLabel>
                <Input
                  value={mobileNo.value}
                  placeholder={i18n[i18n.myLang][mobileNo.placeholder]}
                  onChange={(e) => actions.SetMobileNo({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(mobileNo.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={whatsapp.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][whatsapp.label]}</InputLabel>
                <Input
                  value={whatsapp.value}
                  placeholder={i18n[i18n.myLang][whatsapp.placeholder]}
                  onChange={(e) => actions.SetWhatsApp({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(whatsapp.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <form className={classes.root} autoComplete="off">
              <FormControl fullWidth className={classes.formControl} error={wechat.helperText ? true : false}>
                <InputLabel htmlFor="component-helper">{i18n[i18n.myLang][wechat.label]}</InputLabel>
                <Input
                  value={wechat.value}
                  placeholder={i18n[i18n.myLang][wechat.placeholder]}
                  onChange={(e) => actions.SetWeChat({ value: e.target.value })}
                />
                <FormHelperText>{I18nKey2Value(wechat.helperText, i18n)}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

ContactInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactInfo)