import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import * as asyncInitialState from 'redux-async-initial-state';
import * as reducers from './reducers';
import thunk from 'redux-thunk';
import { API_ROOT, OTHER_PLEASE_SPECIFIC } from './config'

// We need outerReducer to replace full state as soon as it loaded
const reducer = asyncInitialState.outerReducer(combineReducers({
  ...reducers,
  // We need innerReducer to store loading state, i.e. for showing loading spinner
  // If you don't need to handle loading state you may skip it
  asyncInitialState: asyncInitialState.innerReducer,
}));


const loadCarMakes = (getCurrentState) => {
  return new Promise(async (resolve) => {
    fetch(`${API_ROOT}/carmakes`).then(r => r.json()).then(({ data }) => {
      const carMake = { ...getCurrentState().default.yourdetails.carMake, menuItems: data.concat(OTHER_PLEASE_SPECIFIC) }
      resolve(Object.assign(getCurrentState(), { default: { yourdetails: { ...getCurrentState().default.yourdetails, carMake } } }))
    })
  })
}

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        thunk,
        asyncInitialState.middleware(loadCarMakes),
      ),
    ),
  )

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(reducer);
    });
  }

  // tirgger, fix asyncInitialState does not work as newer version redux
  store.dispatch({ type: '' });

  return store
}
