import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    privacyPolicyAgree: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PRIVACY_POLICY_AGREE_FIELD:
            return {
                ...state,
                privacyPolicyAgree: action.payload.value,
            }

        case ActionTypes.RESET_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicyAgree: false,
            }

        default:
            return state
    }
}