import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    open: false,
    variant: 'error',
    autoHideDuration: 3000,
    message: 'dd'
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_OPENSNACKBARS:
            return {
                ...state,
                open: true,
                message: action.payload.message
            }

        case ActionTypes.CLOSE_OPENSNACKBARS:
            return {
                ...state,
                open: false,
                message: ''
            }

        default:
            return state
    }
}