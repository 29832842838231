import i18n from './i18n';
import range from 'lodash/range';

let HOST = 'https://endpoint-dot-kwiksure-20180804.appspot.com'
let PORT = '443'

if (process.env.NODE_ENV !== 'production') {
    HOST = process.env.API_HOST || 'http://localhost'
    PORT = process.env.API_PORT || 3004
}

export const API_ROOT = `${HOST}:${PORT}`

export const OTHER_PLEASE_SPECIFIC = 'global.otherpleasespecific'

export const GLOBAL_REQUIRED = `global.required`

const browserLang = (global.navigator ? (navigator.language || navigator.userLanguage).toLowerCase() : '').replace(/-/, () => '_');
const appLang = browserLang in i18n ? browserLang : 'en';
export const defaultLang = global.localStorage ? (localStorage.getItem('myLang') || appLang).toLowerCase() : appLang.toLowerCase();

export const OCCUPATION_INDUSTRY_MENU_ITEMS = [
    "yourdetails.maindriverdetials.occupation.accountaccountant",
    "yourdetails.maindriverdetials.occupation.advertisingnonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.artistmodelsinger",
    "yourdetails.maindriverdetials.occupation.aviation",
    "yourdetails.maindriverdetials.occupation.banking",
    "yourdetails.maindriverdetials.occupation.bardisconightclub",
    "yourdetails.maindriverdetials.occupation.civilservant",
    "yourdetails.maindriverdetials.occupation.clearingservices",
    "yourdetails.maindriverdetials.occupation.clerknonmotorentertainmenttransportationorlogistics",
    "yourdetails.maindriverdetials.occupation.constructionworkersiteagentforeman",
    "yourdetails.maindriverdetials.occupation.cookchef",
    "yourdetails.maindriverdetials.occupation.decoratorpainter",
    "yourdetails.maindriverdetials.occupation.designgraphicinteriorproductnonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.disciplinaryforces",
    "yourdetails.maindriverdetials.occupation.doctordentist",
    "yourdetails.maindriverdetials.occupation.engineeringnonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.entertainmenttelevisionbroadcast",
    "yourdetails.maindriverdetials.occupation.governmentdeptnoncivilservant",
    "yourdetails.maindriverdetials.occupation.hairstylistmakeupartistskinbeauty",
    "yourdetails.maindriverdetials.occupation.hotel",
    "yourdetails.maindriverdetials.occupation.housewifeunemployed",
    "yourdetails.maindriverdetials.occupation.informationtechnicalcomputerengineer",
    "yourdetails.maindriverdetials.occupation.insurance",
    "yourdetails.maindriverdetials.occupation.investmentfinance",
    "yourdetails.maindriverdetials.occupation.lawyerbarrister",
    "yourdetails.maindriverdetials.occupation.legalservices",
    "yourdetails.maindriverdetials.occupation.logistictrasportation",
    "yourdetails.maindriverdetials.occupation.merchandiserbuyernonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.motorgaragebeautytrade",
    "yourdetails.maindriverdetials.occupation.privatecardriver",
    "yourdetails.maindriverdetials.occupation.propertyagent",
    "yourdetails.maindriverdetials.occupation.propertymanagement",
    "yourdetails.maindriverdetials.occupation.railway",
    "yourdetails.maindriverdetials.occupation.regristednursecaregiver",
    "yourdetails.maindriverdetials.occupation.reportereditor",
    "yourdetails.maindriverdetials.occupation.restaurant",
    "yourdetails.maindriverdetials.occupation.retired",
    "yourdetails.maindriverdetials.occupation.salepersonsretailnonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.secretarypersonalassistantnonmotorentertainment",
    "yourdetails.maindriverdetials.occupation.shippingcargo",
    "yourdetails.maindriverdetials.occupation.socialworker",
    "yourdetails.maindriverdetials.occupation.student",
    "yourdetails.maindriverdetials.occupation.teacherprofessor",
    "yourdetails.maindriverdetials.occupation.telecom",
    "yourdetails.maindriverdetials.occupation.travelagenttourguide",
    "yourdetails.maindriverdetials.occupation.vantruckdriver",
    "yourdetails.maindriverdetials.occupation.waiterwaitress",
    OTHER_PLEASE_SPECIFIC,
]

export const MAIN_DRIVER_DETAILS_PATTEN = {
    age: {
        label: 'yourdetails.typography.maindriverdetails.age',
        value: '',
        menuItems: range(18, 100, 1),
        helperText: GLOBAL_REQUIRED,
    },
    occupationIndustry: {
        label: 'yourdetails.typography.maindriverdetails.occupationindustry',
        value: '',
        menuItems: OCCUPATION_INDUSTRY_MENU_ITEMS,
        helperText: GLOBAL_REQUIRED,
    },
    specificOccupationIndustry: {
        label: 'yourdetails.typography.maindriverdetails.specificoccupationindustry',
        value: '',
        helperText: '',
    },
    yearsOfDrivingExperience: {
        label: 'yourdetails.typography.maindriverdetails.yearsofdrivingexperience',
        value: '',
        menuItems: range(1, 61, 1),
        helperText: GLOBAL_REQUIRED,
    },
    qa1: {
        label: 'yourdetails.typography.maindriverdetails.qa1',
        value: 'global.no',
        menuItems: ['global.yes', 'global.no'],
        helperText: '',
    },
    eq1YesComment: {
        label: 'yourdetails.typography.maindriverdetails.qa1yes',
        value: '',
        helperText: GLOBAL_REQUIRED
    },
    qa2: {
        label: 'yourdetails.typography.maindriverdetails.qa2',
        value: 'global.no',
        menuItems: ['global.yes', 'global.no'],
        helperText: '',
    },
    eq2YesComment: {
        label: 'yourdetails.typography.maindriverdetails.qa2yes',
        value: '',
        helperText: GLOBAL_REQUIRED
    },
}

export const STEPS = [
    "global.stepper.yourdetails",
    "global.stepper.contactinfo",
    "global.stepper.moredetails",
    "global.stepper.reviewyourorder",
    "global.stepper.privacypolicy"
]