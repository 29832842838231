import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import React from 'react'
import App from './containers/app'
import configureStore from './configureStore'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import 'sanitize.css/sanitize.css'
import './index.css'

const store = configureStore()

render(
  <AppContainer>
    <Provider store={store}>
      <MuiThemeProvider>
        <App />
      </MuiThemeProvider>
    </Provider>
  </AppContainer>,
  document.querySelector('#root')
)
