import * as ActionTypes from '../actions/ActionTypes';
import range from 'lodash/range';
import { MAIN_DRIVER_DETAILS_PATTEN, GLOBAL_REQUIRED } from '../config'

const initialState = {
    insurranceDetails: {
        label: 'yourdetails.typography.insurancedetails.label',
        value: '',
        menuItems: [
            'yourdetails.typography.insurancedetails.menuItems.thirdparty',
            'yourdetails.typography.insurancedetails.menuItems.comprehensive',
            'yourdetails.typography.insurancedetails.menuItems.thirdpartyandcomp'
        ],
        helperText: GLOBAL_REQUIRED,
    },
    noClaimsDiscount: {
        label: 'yourdetails.typography.noclaimsdiscount.label',
        value: '',
        menuItems: ['0%', '20%', '30%', '40%', '50%', '60%'],
        helperText: GLOBAL_REQUIRED,
    },
    carMake: {
        label: 'yourdetails.typography.cardetails.carmake.label',
        value: '',
        menuItems: [],
        helperText: GLOBAL_REQUIRED,
    },
    carModel: {
        disabled: false,
        label: 'yourdetails.typography.cardetails.carmodel.label',
        value: '',
        menuItems: [],
        helperText: GLOBAL_REQUIRED,
    },
    specificCarModel: {
        label: 'yourdetails.typography.cardetails.specificcarmodel.label',
        value: '',
        helperText: '',
    },
    yearOfManufacture: {
        label: 'yourdetails.typography.cardetails.yearofmanufacture.label',
        value: '',
        menuItems: range(1988, new Date().getFullYear() + 1, 1),
        helperText: GLOBAL_REQUIRED,
    },
    cubicCapacity: {
        label: 'yourdetails.typography.cardetails.cubiccapacity.label',
        value: '',
        menuItems: ['< 1650', '1651 - 3500', '> 3500'],
        helperText: GLOBAL_REQUIRED,
    },
    noOfNamedDriver: {
        label: 'yourdetails.typography.cardetails.noofnameddriver.label',
        value: 1,
        menuItems: [1, 2],
        helperText: '',
    },
    mainDriverDetails: [
        MAIN_DRIVER_DETAILS_PATTEN
    ]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "redux-async-initial-state/STATE_LOADING_DONE":
            const { yourdetails } = action.payload.state.default
            return {
                ...state,
                ...yourdetails
            }
        case ActionTypes.LOAD_CAR_MAKES:
            return {
                ...state,
                carMake: {
                    ...state.carMake,
                    menuItems: action.payload.menuItems
                }
            }

        case ActionTypes.RESET_YOURDETAILS:
            return {
                ...initialState
            }

        case ActionTypes.SET_CAR_MAKE_FIELD:
            const carMake = {
                ...state.carMake,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED,
            }
            return {
                ...state,
                carMake
            }

        case ActionTypes.SET_CAR_MODEL_FIELD:
            const carModel = {
                ...state.carModel,
                value: action.payload.value,
                disabled: action.payload.disabled,
                menuItems: action.payload.menuItems ? action.payload.menuItems : state.carModel.menuItems,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED,
            }
            return {
                ...state,
                carModel
            }

        case ActionTypes.SET_SPECIFIC_CAR_MODEL_FIELD:
            const specificCarModel = {
                ...state.specificCarModel,
                value: action.payload.value,
                helperText: action.payload.helperText,
            }
            return {
                ...state,
                specificCarModel
            }

        case ActionTypes.SET_INSURANCE_TYPE_FIELD:
            let insurranceDetails = {
                ...state.insurranceDetails,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                insurranceDetails
            }

        case ActionTypes.SET_NO_CLAIMS_DISCOUNT_FIELD:
            const noClaimsDiscount = {
                ...state.noClaimsDiscount,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                noClaimsDiscount
            }

        case ActionTypes.SET_YEAR_OF_MANUFACTURE_FIELD:
            let yearOfManufacture = {
                ...state.yearOfManufacture,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                yearOfManufacture
            }

        case ActionTypes.LOAD_CAR_MODELS:
            return {
                ...state,
                carModel: { ...state.carModel, menuItems: action.payload.menuItems }
            }

        case ActionTypes.SET_CUBICCAPACITY_FIELD:
            let cubicCapacity = {
                ...state.cubicCapacity,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                cubicCapacity
            }

        case ActionTypes.SET_NO_OF_NAMED_DRIVER_FIELD:
            let noOfNamedDriver = {
                ...state.noOfNamedDriver,
                value: action.payload.value,
                helperText: action.payload.value ? '' : GLOBAL_REQUIRED
            }
            return {
                ...state,
                noOfNamedDriver,
            }

        case ActionTypes.SET_MAIN_DRIVER_DETAILS_FIELD:
            return {
                ...state,
                mainDriverDetails: action.payload.mainDriverDetails
            }

        case ActionTypes.SET_AGE_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        age: {
                            ...mdd.age,
                            value: action.payload.value,
                            helperText: action.payload.value ? '' : GLOBAL_REQUIRED
                        }
                    }
                })
            }

        case ActionTypes.SET_OCCUPATION_INDUSTRY_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        occupationIndustry: {
                            ...mdd.occupationIndustry,
                            value: action.payload.value,
                            helperText: action.payload.value ? '' : GLOBAL_REQUIRED
                        }
                    }
                })
            }

        case ActionTypes.SET_SPECIFIC_OCCUPATION_INDUSTRY_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    const specificOccupationIndustry = {
                        ...mdd.specificOccupationIndustry,
                        value: action.payload.value,
                        helperText: action.payload.helperText
                    }
                    return {
                        ...mdd,
                        specificOccupationIndustry
                    }
                })
            }

        case ActionTypes.SET_YEARS_OF_DRIVING_EXPERIENCE_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        yearsOfDrivingExperience: {
                            ...mdd.yearsOfDrivingExperience,
                            value: action.payload.value,
                            helperText: action.payload.value ? '' : GLOBAL_REQUIRED
                        }
                    }
                })
            }
        case ActionTypes.SET_QA1_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        qa1: {
                            ...mdd.qa1,
                            value: action.payload.value,
                        }
                    }
                })
            }

        case ActionTypes.SET_EQ1_YES_COMMENT:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        eq1YesComment: {
                            ...mdd.eq1YesComment,
                            value: action.payload.value,
                            helperText: action.payload.value ? '' : GLOBAL_REQUIRED,
                        }
                    }
                })                
            }

        case ActionTypes.SET_QA2_FIELD:
            return {
                ...state,
                mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                    if (index !== action.payload.index) {
                        return mdd
                    }
                    return {
                        ...mdd,
                        qa2: {
                            ...mdd.qa2,
                            value: action.payload.value,
                        }
                    }
                })
            }

        case ActionTypes.SET_EQ2_YES_COMMENT:
                return {
                    ...state,
                    mainDriverDetails: state.mainDriverDetails.map((mdd, index) => {
                        if (index !== action.payload.index) {
                            return mdd
                        }
                        return {
                            ...mdd,
                            eq2YesComment: {
                                ...mdd.eq2YesComment,
                                value: action.payload.value,
                                helperText: action.payload.value ? '' : GLOBAL_REQUIRED,
                            }
                        }
                    })                
                }

        default:
            return state
    }
}