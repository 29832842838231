import { equals, always, T, identity, cond } from 'ramda'
var pathParse = require('path-parse');

export const localeMapping = {
    en: 'English',
    zh_tw: '繁體中文',
    zh_cn: '简体中文'
};

export const mapi18nkey2Lang = cond([
    [equals('en'), always('English')],
    [equals('zh_tw'), always('繁體中文')],
    [equals('zh_cn'), always('简体中文')],
    [T, identity]
]);

export const mapLang2i18nKey = cond([
    [equals('EN'), always('en')],
    [equals('CHT'), always('zh_tw')],
    [equals('CHS'), always('zh_cn')],
    [T, identity]
]);

const importAllLocales = (ctx, cb) => ctx.keys().reduce((acc, curr) => {
    const p = pathParse(curr)
    acc[mapLang2i18nKey(p.name).toLowerCase()] = ctx(curr).default
    return acc
}, {})

const locales = importAllLocales(require.context("./", true, /^\.\/locales\/.*\.js$/))

export default {
    ...locales,
    // 'en-us': locales.en,
};
