import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Slice from 'lodash/slice';
import { I18nKey2Value } from '../../utils/functions'

const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});

class Review extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      classes,
      yourdetails: {
        insurranceDetails,
        noClaimsDiscount,
        carMake,
        carModel,
        specificCarModel,
        yearOfManufacture,
        cubicCapacity,
        noOfNamedDriver,
        mainDriverDetails,
      },
      contact: {
        name,
        email,
        mobileNo,
        whatsapp,
        wechat,
      },
      moredetails: {
        approximateStartDate,
        isLocation,
        remark,
        referralCode,
      },
      i18n,
    } = this.props;

    return (
      <React.Fragment>
        <Typography variant="title" gutterBottom>
          {i18n[i18n.myLang]["global.stepper.yourdetails"]}
        </Typography>
        <List disablePadding>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][insurranceDetails.label]}>
            <ListItemText primary={i18n[i18n.myLang][insurranceDetails.label]} />
            <Typography variant="body2">{insurranceDetails.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][noClaimsDiscount.label]}>
            <ListItemText primary={i18n[i18n.myLang][noClaimsDiscount.label]} />
            <Typography variant="body2">{noClaimsDiscount.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][carMake.label]}>
            <ListItemText primary={i18n[i18n.myLang][carMake.label]} />
            <Typography variant="body2">{I18nKey2Value(carMake.value, i18n)}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][carModel.label]}>
            <ListItemText primary={i18n[i18n.myLang][carModel.label]} />
            <Typography variant="body2">{I18nKey2Value(carModel.value, i18n)}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][specificCarModel.label]}>
            <ListItemText primary={i18n[i18n.myLang][specificCarModel.label]} />
            <Typography variant="body2">{specificCarModel.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][yearOfManufacture.label]}>
            <ListItemText primary={i18n[i18n.myLang][yearOfManufacture.label]} />
            <Typography variant="body2">{yearOfManufacture.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][cubicCapacity.label]}>
            <ListItemText primary={i18n[i18n.myLang][cubicCapacity.label]} />
            <Typography variant="body2">{cubicCapacity.value}</Typography>
          </ListItem>
          <ListItem className={classes.listItem} key={i18n[i18n.myLang][noOfNamedDriver.label]}>
            <ListItemText primary={i18n[i18n.myLang][noOfNamedDriver.label]} />
            <Typography variant="body2">{noOfNamedDriver.value}</Typography>
          </ListItem>

          {Slice(mainDriverDetails, 0, noOfNamedDriver.value).map((mdd, index) => (
            <Grid item xs={12} sm={12} key={`main-driver-details-${index}`}>
              <Divider light />
              <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.age.label]}>
                <ListItemText primary={i18n[i18n.myLang][mdd.age.label]} />
                <Typography variant="body2">{mdd.age.value}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.occupationIndustry.label]}>
                <ListItemText primary={i18n[i18n.myLang][mdd.occupationIndustry.label]} />
                <Typography variant="body2">{I18nKey2Value(mdd.occupationIndustry.value, i18n)}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.specificOccupationIndustry.label]}>
                <ListItemText primary={i18n[i18n.myLang][mdd.specificOccupationIndustry.label]} />
                <Typography variant="body2">{mdd.specificOccupationIndustry.value}</Typography>
              </ListItem>
              <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.yearsOfDrivingExperience.label]}>
                <ListItemText primary={i18n[i18n.myLang][mdd.yearsOfDrivingExperience.label]} />
                <Typography variant="body2">{mdd.yearsOfDrivingExperience.value}</Typography>
              </ListItem>
              <Grid container style={{ marginTop: '8px' }}>
                <React.Fragment>
                  <Grid item xs={11}>
                    <ListItemText primary={i18n[i18n.myLang][mdd.qa1.label]} />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography gutterBottom align={'right'}>{i18n[i18n.myLang][mdd.qa1.value]} </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
              {mdd.qa1.value === 'global.yes' ? (
                <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.eq1YesComment.label]}>
                  <ListItemText primary={i18n[i18n.myLang][mdd.eq1YesComment.label]} />
                  <Typography variant="body2">{I18nKey2Value(mdd.eq1YesComment.value, i18n)}</Typography>
                </ListItem>
              ) : ('')}
              <Grid container style={{ marginTop: '0px' }}>
                <React.Fragment>
                  <Grid item xs={11}>
                    <ListItemText primary={i18n[i18n.myLang][mdd.qa2.label]} />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography gutterBottom align={'right'}>{i18n[i18n.myLang][mdd.qa2.value]}</Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
              {mdd.qa2.value === 'global.yes' ? (
                <ListItem className={classes.listItem} key={i18n[i18n.myLang][mdd.eq2YesComment.label]}>
                  <ListItemText primary={i18n[i18n.myLang][mdd.eq2YesComment.label]} />
                  <Typography variant="body2">{I18nKey2Value(mdd.eq2YesComment.value, i18n)}</Typography>
                </ListItem>
              ) : ('')}
            </Grid>
          ))}
        </List>

        <div style={{ marginTop: 20 }}>
          <Typography variant="title" gutterBottom>
            {i18n[i18n.myLang]["global.stepper.contactinfo"]}
          </Typography>
          <List disablePadding>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][name.label]}>
              <ListItemText primary={i18n[i18n.myLang][name.label]} />
              <Typography variant="body2">{name.value}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][email.label]}>
              <ListItemText primary={i18n[i18n.myLang][email.label]} />
              <Typography variant="body2">{email.value}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][mobileNo.label]}>
              <ListItemText primary={i18n[i18n.myLang][mobileNo.label]} />
              <Typography variant="body2">{mobileNo.value}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][whatsapp.label]}>
              <ListItemText primary={i18n[i18n.myLang][whatsapp.label]} />
              <Typography variant="body2">{whatsapp.value}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][wechat.label]}>
              <ListItemText primary={i18n[i18n.myLang][wechat.label]} />
              <Typography variant="body2">{wechat.value}</Typography>
            </ListItem>
          </List>
        </div>

        <div style={{ marginTop: 20 }}>
          <Typography variant="title" gutterBottom>
            {i18n[i18n.myLang]["global.stepper.moredetails"]}
          </Typography>
          <List disablePadding>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][approximateStartDate.label]}>
              <ListItemText primary={i18n[i18n.myLang][approximateStartDate.label]} />
              <Typography variant="body2">{approximateStartDate.value}</Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][isLocation.label]}>
              <ListItemText primary={i18n[i18n.myLang][isLocation.label]} />
              <Typography variant="body2">{i18n[i18n.myLang][isLocation.value]}</Typography>
            </ListItem>
            <Grid container style={{ marginTop: '8px' }}>
              <React.Fragment>
                <Grid item xs={2}>
                  <ListItemText primary={i18n[i18n.myLang][remark.label]} />
                </Grid>
                <Grid item xs={10}>
                  <Typography gutterBottom align={'left'}>{remark.value} </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
            <ListItem className={classes.listItem} key={i18n[i18n.myLang][referralCode.label]}>
              <ListItemText primary={i18n[i18n.myLang][referralCode.label]} />
              <Typography variant="body2">{referralCode.value}</Typography>
            </ListItem>
          </List>
        </div>

      </React.Fragment>
    );
  }
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review)

