import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slice from 'lodash/slice';
import MySelect from '../../components/MySelect'
import { OTHER_PLEASE_SPECIFIC } from '../../config'
import { I18nKey2Value } from '../../utils/functions'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    marginTop: 0
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class YourDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes,
      actions,
      i18n,
      yourdetails: {
        insurranceDetails,
        noClaimsDiscount,
        carMake,
        carModel,
        specificCarModel,
        yearOfManufacture,
        cubicCapacity,
        noOfNamedDriver,
        mainDriverDetails,
      }
    } = this.props;

    return (
      <React.Fragment>
        <Typography variant="title" gutterBottom>
          {i18n[i18n.myLang]['yourdetails.typography.insurancedetails.title']}
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12}>
            <MySelect
              i18n={i18n}
              error={insurranceDetails.helperText ? true : false}
              value={insurranceDetails.value}
              items={insurranceDetails.menuItems.map(key => i18n[i18n.myLang][key])}
              label={i18n[i18n.myLang][insurranceDetails.label]}
              helperText={insurranceDetails.helperText}
              onChange={(value) => actions.SetInsurnaceType({ value })} />
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
              <Typography variant="title" gutterBottom className={classes.title}>
                {i18n[i18n.myLang]["yourdetails.typography.noclaimsdiscount.title"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MySelect
                i18n={i18n}
                error={noClaimsDiscount.helperText ? true : false}
                value={noClaimsDiscount.value}
                items={noClaimsDiscount.menuItems}
                label={i18n[i18n.myLang][noClaimsDiscount.label]}
                helperText={noClaimsDiscount.helperText}
                onChange={(value) => actions.SetNoClaimsDiscount({ value })} />
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: 20 }}>
          <Grid container spacing={8}>
            <Typography variant="title" gutterBottom>
              {i18n[i18n.myLang]["yourdetails.typography.cardetails.title"]}
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={8} sm={4}>
                <MySelect
                  i18n={i18n}
                  error={carMake.helperText ? true : false}
                  value={carMake.value}
                  items={carMake.menuItems}
                  label={i18n[i18n.myLang][carMake.label]}
                  helperText={carMake.helperText}
                  onChange={(value) => actions.SetCarMake({ value })} />
              </Grid>
              <Grid item xs={8} sm={4}>
                <MySelect
                  i18n={i18n}
                  error={carModel.helperText ? true : false}
                  value={carModel.value}
                  disabled={carModel.disabled}
                  items={carModel.menuItems}
                  label={i18n[i18n.myLang][carModel.label]}
                  helperText={carModel.helperText}
                  onChange={(value, isLastItem) => actions.SetCarModel({ value, isLastItem })} />
              </Grid>
              {carModel.value === OTHER_PLEASE_SPECIFIC ? (
                <Grid item xs={8} sm={4}>
                  <form className={classes.root} autoComplete="off">
                    <FormControl fullWidth className={classes.formControl} error={specificCarModel.helperText ? true : false}>
                      <InputLabel htmlFor htmlFor={specificCarModel.label}>{i18n[i18n.myLang][specificCarModel.label]}</InputLabel>
                      <Input
                        id={i18n[i18n.myLang][specificCarModel.label]}
                        value={specificCarModel.value}
                        aria-describedby={`${i18n[i18n.myLang][specificCarModel.label]}-text`}
                        onChange={(e) => actions.SetSpecificCarModel({ value: e.target.value })}
                      />
                      <FormHelperText>{I18nKey2Value(specificCarModel.helperText, i18n)}</FormHelperText>
                    </FormControl>
                  </form>
                </Grid>
              ) : ("")}
              <Grid item xs={12} sm={12}>
                <MySelect
                  i18n={i18n}
                  error={yearOfManufacture.helperText ? true : false}
                  value={yearOfManufacture.value}
                  items={yearOfManufacture.menuItems}
                  label={i18n[i18n.myLang][yearOfManufacture.label]}
                  helperText={yearOfManufacture.helperText}
                  onChange={(value) => actions.SetYearOfManufacture({ value })} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MySelect
                  i18n={i18n}
                  error={cubicCapacity.helperText ? true : false}
                  value={cubicCapacity.value}
                  items={cubicCapacity.menuItems}
                  label={i18n[i18n.myLang][cubicCapacity.label]}
                  helperText={cubicCapacity.helperText}
                  onChange={(value) => actions.SetCubicCapacity({ value })} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MySelect
                  i18n={i18n}
                  error={noOfNamedDriver.helperText ? true : false}
                  value={noOfNamedDriver.value}
                  items={noOfNamedDriver.menuItems}
                  label={i18n[i18n.myLang][noOfNamedDriver.label]}
                  helperText={noOfNamedDriver.helperText}
                  onChange={(value) => actions.SetNoOfNamedDriver({ value })} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 20 }}>
          <Grid container spacing={8}>
            <Typography variant="title" gutterBottom className={classes.title}>
              {i18n[i18n.myLang]["yourdetails.typography.maindriverdetails.title"]}
            </Typography>
            <Grid container spacing={8}>
              {Slice(mainDriverDetails, 0, noOfNamedDriver.value).map((mdd, index) => (
                <Grid item xs={12} sm={12} key={`main-driver-details-${index}`}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={12}>
                      <MySelect
                        i18n={i18n}
                        error={mdd.age.helperText ? true : false}
                        value={mdd.age.value}
                        items={mdd.age.menuItems}
                        label={i18n[i18n.myLang][mdd.age.label]}
                        helperText={mdd.age.helperText}
                        onChange={(value) => actions.SetAge({ index, value })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MySelect
                        i18n={i18n}
                        error={mdd.occupationIndustry.helperText ? true : false}
                        value={mdd.occupationIndustry.value}
                        items={mdd.occupationIndustry.menuItems}
                        label={i18n[i18n.myLang][mdd.occupationIndustry.label]}
                        helperText={mdd.occupationIndustry.helperText}
                        onChange={(value, isLastItem) => actions.SetOccupationIndustry({ index, value, isLastItem })}
                      />
                    </Grid>
                    {mdd.occupationIndustry.value === OTHER_PLEASE_SPECIFIC ? (
                      <Grid item xs={12} sm={6}>
                        <form className={classes.root} autoComplete="off">
                          <FormControl fullWidth className={classes.formControl} error={mdd.specificOccupationIndustry.helperText ? true : false}>
                            <InputLabel>{i18n[i18n.myLang][mdd.specificOccupationIndustry.label]}</InputLabel>
                            <Input
                              id={i18n[i18n.myLang][mdd.specificOccupationIndustry.label]}
                              value={mdd.specificOccupationIndustry.value || ''}
                              aria-describedby={`${i18n[i18n.myLang][mdd.specificOccupationIndustry.label]}-text`}
                              onChange={(e) => actions.SetSpecificOccupationIndustry({ index, value: e.target.value })}
                            />
                            <FormHelperText>{I18nKey2Value(mdd.specificOccupationIndustry.helperText, i18n)}</FormHelperText>
                          </FormControl>
                        </form>
                      </Grid>
                    ) : ('')}
                    <Grid item xs={12} sm={12}>
                      <MySelect
                        i18n={i18n}
                        error={mdd.yearsOfDrivingExperience.helperText ? true : false}
                        value={mdd.yearsOfDrivingExperience.value}
                        items={mdd.yearsOfDrivingExperience.menuItems}
                        label={i18n[i18n.myLang][mdd.yearsOfDrivingExperience.label]}
                        helperText={mdd.yearsOfDrivingExperience.helperText}
                        onChange={(value) => actions.SetYearsOfDrivingExperience({ index, value })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset" className={classes.formControl} error={mdd.qa1.helperText ? true : false}>
                        <FormLabel component="legend">{i18n[i18n.myLang][mdd.qa1.label]}</FormLabel>
                        <RadioGroup
                          row
                          aria-label="Gender"
                          className={classes.group}
                          value={i18n[i18n.myLang][mdd.qa1.value]}
                          data-key={mdd.qa1.value}
                          onChange={(e) => actions.SetQa1({ index, value: e.target.parentElement.parentElement.parentElement.dataset.key })}
                        >
                          {mdd.qa1.menuItems.map(qa => (
                            <FormControlLabel key={`qa-${index}-${qa}`} data-key={qa} value={i18n[i18n.myLang][qa]} control={<Radio />} label={i18n[i18n.myLang][qa]} />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    { mdd.qa1.value == 'global.yes' ? (
                      <Grid item xs={12} sm={12}>
                        <form className={classes.root} autoComplete="off">
                          <FormControl fullWidth className={classes.formControl} error={mdd.eq1YesComment.helperText ? true : false}>                          
                            <InputLabel htmlFor htmlFor={mdd.eq1YesComment.label}>{i18n[i18n.myLang][mdd.eq1YesComment.label]}</InputLabel>
                            <Input
                              id={i18n[i18n.myLang][mdd.eq1YesComment.label]}
                              value={mdd.eq1YesComment.value}
                              aria-describedby={`${i18n[i18n.myLang][mdd.eq1YesComment.label]}-text`}
                              onChange={(e) => actions.SetEq1YesComment({ index, value: e.target.value })}
                            />
                            <FormHelperText>{I18nKey2Value(mdd.eq1YesComment.helperText, i18n)}</FormHelperText>
                          </FormControl>
                        </form>
                      </Grid>
                    ) : ('')}
                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset" className={classes.formControl} error={mdd.qa2.helperText ? true : false}>
                        <FormLabel component="legend" style={{ lineHeight: '1.5em' }}>{i18n[i18n.myLang][mdd.qa2.label]}</FormLabel>
                        <RadioGroup
                          row
                          aria-label="Gender"
                          className={classes.group}
                          value={i18n[i18n.myLang][mdd.qa2.value]}
                          onChange={(e) => actions.SetQa2({ index, value: e.target.parentElement.parentElement.parentElement.dataset.key })}>
                          {mdd.qa2.menuItems.map(qa => (
                            <FormControlLabel key={`qa-${index}-${qa}`} data-key={qa} value={i18n[i18n.myLang][qa]} control={<Radio />} label={i18n[i18n.myLang][qa]} />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {mdd.qa2.value == 'global.yes' ? (
                      <Grid item xs={12} sm={12}>
                        <form className={classes.root} autoComplete="off">
                          <FormControl fullWidth className={classes.formControl} error={mdd.eq2YesComment.helperText ? true : false}>
                            <InputLabel htmlFor htmlFor={mdd.eq2YesComment.label}>{i18n[i18n.myLang][mdd.eq2YesComment.label]}</InputLabel>
                            <Input
                              id={i18n[i18n.myLang][mdd.eq2YesComment.label]}
                              value={mdd.eq2YesComment.value}
                              aria-describedby={`${i18n[i18n.myLang][mdd.eq2YesComment.label]}-text`}
                              onChange={(e) => actions.SetEq2YesComment({ index, value: e.target.value })}
                            />
                            <FormHelperText>{I18nKey2Value(mdd.eq2YesComment.helperText, i18n)}</FormHelperText>
                          </FormControl>
                        </form>
                      </Grid>
                    ) : ('')}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </React.Fragment >
    )
  }
}

YourDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(YourDetails)
